@import "variables.scss";

.RecipeHero {
  position: relative;
  width: calc(100% + 32px);

  margin-bottom: #{-8px - 16px};

  .image {
    display: block;
    width: 100%;
    height: auto;
    // background-size: cover;
    opacity: 0;
  }
  .PlaceholderImage {
    // Responsive
    height: $header-height-mobile;
    @media (min-width: $breakpoint-desktop) {
      height: $header-height-desktop;
    }
    opacity: 0;
  }

  .back {
    position: absolute;
    left: 16px;
    bottom: #{12px + 16px};
  }
  .edit {
    position: absolute;
    right: 16px;
    bottom: #{12px + 16px};
  }
}