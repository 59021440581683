.ChipMenu {
  position: absolute;
  width: 100%;
  bottom: 12px;
  // top: #{-40px - 12px};

  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  justify-content: space-between;

  .chipList {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}