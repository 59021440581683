@import "variables.scss";

.Menu {
  position: relative;
  
  .scrollable {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    // Responsive
    max-width: $content-width-mobile;
    // margin-top: $header-height-mobile;
    @media (min-width: $breakpoint-desktop) {
      max-width: $content-width-desktop;
      // margin-top: $header-height-desktop;
    }

    .topSection {
      position: relative;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      // Responsive
      height: $header-height-mobile;
      @media (min-width: $breakpoint-desktop) {
        height: $header-height-desktop;
      }

      .logo {
        background: url("../theme/logo-taste.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        // Responsive
        transform: translate(2px, -16px);
        width: 154px;
        height: 154px;
        @media (min-width: $breakpoint-desktop) {
          transform: translate(4px, -36px);
          width: 314px;
          height: 314px;
        }
      }
    }

    // Don't block scrollable from clicking on the background
    pointer-events: none;
    > * {
      &:not(:first-child) {
        pointer-events: auto;
      }
      .ChipMenu {
        pointer-events: auto;
      }
    }
  }

}

body:has(.Menu) {
  &::-webkit-scrollbar-track {
    background: $surface;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-minus4;
    border-color: $surface;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-minus2;
  }
}
