@import "variables.scss";

// Scrollbar Shape
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: 4px solid;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, span {
  pointer-events: none;
}
h1, h2, h3, h4, h5, span, input, textarea {
  color: $onElevation-highEmphasis;
}
h1 {
  margin: 0;

  font-family: $title-family;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: normal;
}
// h2 {
//   font-family: $title-family;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   // line-height: 33px;
// }
h3 {
  margin: 0px;

  font-family: $subtitle-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // line-height: 24px;
}
// h4 {
//   margin-top: 16px;
//   font-family: $subtitle-family;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 16px;
//   // line-height: 33px;
// }
// h5 {
//   font-family: $subtitle-family;
//   font-size: 14px;
//   font-weight: 500;
//   opacity: 0.6;
//   text-transform: uppercase;
// }
h6 {
  margin: 0px;

  font-family: $subtitle-family;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

span, p, input, textarea {
  margin: 0;
  font-family: $runningText-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.icon {
  color: $onContent;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

body {
  background-color: black;
  min-height: calc(100vh + 1px);
}

button, input, textarea, iframe {
  outline: none;
  border-style: none;
  padding: 0px;
}
button {
  background: none;
}

// button.default {
//   color: $onSurface-highEmphasis;
//   background-color: $elevation-plus2;
//   border-radius: 2px;

//   padding: 4px 16px;

//   &:hover {
//     background-color: $elevation-plus8;
//   }
//   &:active {
//     background-color: $elevation-minus2;
//   }
// }
// input.default, textarea.default {
//   min-height: 38px;
//   background-color: $elevation-minus2;
//   border-radius: $border;

//   padding: 8px 16px;
// }
