@import "variables.scss";

.RecipeCard {
  position: relative;
  // opacity: 0.2;
  // width: 201px;
  background-color: $elevation;

  border-radius: 4px;

  .image {
    height: 120px;
    width: 100%;
    border-radius: 4px 4px 0px 0px;

    &.url {
      background-size: cover;
      background-position: center;
    }
  }
  .label {
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    >div {
      height: 20px;
      width: 160px;
      background-color: $onElevation-placeholder;

      border-radius: 2px;
    }
  }
}