@import "variables.scss";

@mixin scrollbars() {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: $elevation-minus2;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: $elevation-minus1;
  }

  // For Internet Explorer
  & {
    // scrollbar-face-color: $elevation-plus8;
    // scrollbar-track-color: $elevation-plus2;
  }
}