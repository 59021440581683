@import "variables.scss";

.LimitedIngredientPanel {
  background-color: $elevation;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  gap: 8px;
  padding: 16px 24px;
}