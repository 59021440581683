@import "variables.scss";

.Ingredient {
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;

  display: flex;
  flex-direction: row;
  align-items: left;

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &.title span {
    font-weight: 700;
  }

  span {
    &:first-child {
      flex-shrink: 0;
      text-align: right;
      width: #{2 * $column-width + $column-gap};
    }
    &:nth-child(2) {
      flex-grow: 1;
      margin-left: $column-gap;
    }
  }
}
