@import "variables.scss";

.SearchPanel {
  position: relative;
  z-index: 2;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;

  background-color: $elevation;
  border-radius: 4px;

  margin-top: -60px;
  @keyframes slideUp {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: -60px;
    }
  }
  animation: slideUp 0.2s ease-out forwards;
  
  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 2px;

  .search {
    position: relative;
    flex-grow: 1;

    background-color: $elevation-minus1;
    border-radius: 2px 0px 0px 2px;

    padding: 0px 16px;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    
    input {
      width: 100%;
      background-color: $elevation-minus1;
    }

  }
  .clear {
    flex-shrink: 0;
    width: 40px;
    height: 40px;

    background-color: $elevation-minus1;
    border-radius: 0px 2px 2px 0px;
  }
}