@import "variables.scss";

.RecipeBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: linear-gradient(180deg, #aaaaaa 0%, #bababa 100%);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .image,
  .PlaceholderImage {
    display: block;
    flex-shrink: 0;
    width: 100%;

    // Responsive
    max-width: $content-width-mobile;
    @media (min-width: $breakpoint-desktop) {
      max-width: $content-width-desktop;
    }
  }

  .PlaceholderImage {
    // Responsive
    height: $header-height-mobile;
    @media (min-width: $breakpoint-desktop) {
      height: $header-height-desktop;
    }
  }

  .bottomSection {
    width: 100%;
    flex-grow: 1;
    background: linear-gradient(180deg, #ECECEC 0%, #E9E9E9 100%);

    // Responsive
    max-width: $content-width-mobile;
    @media (min-width: $breakpoint-desktop) {
      max-width: $content-width-desktop;
    }
  }
}