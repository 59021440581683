@import "variables.scss";

.CreateRecipeChip {
  position: fixed;
  z-index: 1;
  bottom: 16px;
  
  width: 48px;
  height: 48px;
  background-color: $elevation;
  box-shadow: $shadow-minus2;
  border-radius: 999px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  // Responsive
  right: calc(50% - ($content-width-mobile * 0.5) + 16px);
  @media (min-width: $breakpoint-desktop) {
    right: calc(50% - ($content-width-desktop * 0.5) + 16px);
  }

  .icon {
    color: $onElevation-highEmphasis;
  }
}