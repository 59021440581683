@import "variables.scss";

.RecipeList {
  position: relative;
  width: 100%;

  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 80px;
  gap: 4px;

  display: grid;
  align-content: stretch;

  // Responsive
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  @media (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(auto-fill, minmax(201px, 1fr));
  }

  .backgroundBlur {
    position: absolute;
    backdrop-filter: blur(32px);
    left: 4px;
    right: 4px;
    height: 100%;
  }
}
