@import "variables.scss";

.ListFilter {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .tag {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;

    &:hover {
      .checkbox div {
        background-color: $elevation-minus2;
      }
    }
    &:active {
      .checkbox div {
        width: 8px;
        height: 8px;
      }
      &.active .checkbox div {
        background-color: $elevation-minus2;
      }
    }

    .checkbox {
      flex-grow: 0;
      width: 16px;
      height: 16px;

      display: flex;
      align-items: center;
      justify-content: center;

      div {
        width: 16px;
        height: 16px;
        background-color: $elevation-minus1;
        border-radius: 2px;
      }

    }
    &.active .checkbox div {
      width: 8px;
      height: 8px;
      background-color: $onElevation-highEmphasis-bold;
    }

    p {
      text-align: left;
      flex-grow: 1;
    }
  }
}