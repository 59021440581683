@import "variables.scss";
@import "Scrollbar.scss";

.Dropdown {
  .taste-select__control {
    background-color: $elevation-minus1;
    border: none;
    border-radius: 2px;

    .taste-select__input-container {
      color: $onElevation-highEmphasis;
    }
  }
  .taste-select__control:hover {
    // dropdown hover effect
    box-shadow: none;
    // background-color: $elevation-minus2;
  }
  .taste-select__control:active,
  .taste-select__control.taste-select__control--is-focused,
  .taste-select__control.taste-select__control--menu-is-open,
  .taste-select__control:focus {
    // dropdown hover effect
    box-shadow: none;
  }
  .taste-select__indicator {
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      color: $onElevation-highEmphasis;
    }
  }
  .taste-select__indicator-separator {
    display: none;
  }
  .taste-select__single-value {
    color: $onElevation-highEmphasis;
    text-align: center;

    font-size: 14px;
    font-family: $runningText-family;
  }
  .taste-select__menu {
    // popup panel
    margin-top: 1px;
    background-color: $elevation;
    border-radius: 2px;
    overflow: hidden;
    text-align: center;
    box-shadow: $shadow;

    .taste-select__option {
      // popup deselected item
      color: $onElevation-highEmphasis;
    }
    .taste-select__option--is-focused,
    .taste-select__option--is-selected {
      // popup selected item
      color: $onElevation-highEmphasis;
      background-color: $elevation-minus1;
    }
    .taste-select__menu-list {
      @include scrollbars();
    }
  }
}