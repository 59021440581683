@import "variables.scss";

.MenuBackground {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $surface;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .topSection {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
    // Responsive
    max-width: $content-width-mobile;
    height: calc($header-height-mobile + 40px);
    @media (min-width: $breakpoint-desktop) {
      max-width: 1140px;
      height: calc($header-height-desktop + 120px);
    }
  }

  .bottomSection {
    width: 100%;
    flex-grow: 1;

    // Responsive
    max-width: $content-width-mobile;
    @media (min-width: $breakpoint-desktop) {
      max-width: $content-width-desktop;
    }
  }
  
  // Menu specific
  &.kitchen {
    .topSection {
      background: url("../theme/hero-kitchen-default.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .bottomSection {
      background: url("../theme/hero-kitchen-default.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: scale(1, -1);
      filter: blur(32px);
    }
  }
  &.bar {
    .topSection {
      background: url("../theme/hero-bar-default.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .bottomSection {
      background: url("../theme/hero-bar-default.png");
      // background-size: cover;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: scale(1, -1);
      filter: blur(32px);
    }
  }
}