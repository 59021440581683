@import "variables.scss";

.RecipeHeader {
  width: 100%;

  background-color: $elevation;
  border-radius: 4px;

  text-align: center;
  padding: 20px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  
  h1 {
    color: $onElevation-highEmphasis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .recommendation {
    text-align: left;
    max-width: 360px;
  }
  .icon {
    color: $onElevation-highEmphasis;
    opacity: 0.8;
  }
}