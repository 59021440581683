@import "variables.scss";

.FilterSection {
  background-color: $elevation;
  padding: 16px;

  .label {
    margin-left: 8px;
    margin-bottom: 12px;
  }
}