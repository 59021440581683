.FilterPanel {
  position: relative;
  z-index: 2;
  margin-bottom: 4px;

  margin-top: -60px;
  @keyframes slideUp {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: -60px;
    }
  }
  animation: slideUp 0.2s ease-out forwards;
  
  padding-left: 4px;
  padding-right: 4px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;

  > *:first-child {
    border-radius: 4px 4px 0px 0px;
  }
  > *:last-child {
    border-radius: 0px 0px 4px 4px;
  }
}