@import "variables.scss";

.AuthorTag {
  position: relative;
  text-align: right;
  padding-top: 8px;

  &.url {
    padding-bottom: 8px;
  }

  > * {
    margin-left: auto;
    
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(6px);
    border-radius: 999px;
    
    padding: 8px 16px;

    span {
      color: $onElevation-highEmphasis;
      opacity: 0.8;
    }
  }
}