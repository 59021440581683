@import "variables.scss";

.Chip {
  width: 48px;
  height: 48px;
  
  border-radius: 999px;
  background-color: $onContent-background;
  backdrop-filter: blur(6px);
  
  display: flex;
  justify-content: center;
  align-items: center;

  &:active {
    background-color: $onContent-background-minus2;
  }
}