@import "variables.scss";

.IngredientListButton {
  position: relative;
  background-color: $elevation;

  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  

  .icon {
    margin-left: 97px;

    color: $onElevation-highEmphasis;
  }
  span {
    margin-left: 40px;

    font-weight: 700;
  }
}
