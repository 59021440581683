@import "variables.scss";

.PreparationPhase {
  background-color: $elevation;
  padding: 32px 16px;

  .header {
    span {
      font-weight: 700;
      margin-bottom: 8px;

      &:first-child {
        display: inline-block;
        text-align: right;
        width: $column-width; 
      }
      &:nth-child(2) {
        margin-left: $column-gap;
      }
    }
  }
  
  .steps p {
    margin-left: #{$column-width + $column-gap};
    margin-top: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
