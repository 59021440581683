@import "variables.scss";

.FilterHeader {
  background-color: $elevation;

  display: flex;
  flex-direction: row;
  align-items: center;

  p, span {
    position: relative;
    // line-height: 20px;
    
    sup {
      position: absolute;
      top: -6px;
    }
  }

  .filterTab {
    padding-top: 12px;
    padding-bottom: 12px;
    flex-grow: 1;

    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
    &.reset {
      flex-grow: 0;
      margin-right: 0px;
    }

    &.active {
      span, p {
        font-weight: 700;
      }
    }
  }

  .filterTab.reset {
    padding-left: 16px;
    padding-right: 16px;

    .icon {
      color: $onElevation-highEmphasis;
    }
  }
}