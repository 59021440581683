@import "variables.scss";

.FilterResultCounter {
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  background-color: $elevation;
  border-radius: 999px;
  box-shadow: $shadow;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;

  span {
    font-weight: 700;
  }
}