@import "variables.scss";

.Recipe {
  position: relative;
  
  .scrollable {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    // min-height: 100.1vh;
    // overflow-y: scroll;

    // background-color: magenta;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;

    // Responsive
    max-width: $content-width-mobile;
    // margin-top: $header-height-mobile;
    @media (min-width: $breakpoint-desktop) {
      max-width: $content-width-desktop;
      // margin-top: $header-height-desktop;
    }

    .instructions {
      width: 100%;

      backdrop-filter: blur(32px);

      display: flex;
      justify-content: center;
      align-items: flex-start;
      // flex-wrap: wrap;
      
      // Responsive
      flex-direction: column;
      @media (min-width: $breakpoint-desktop) {
        flex-direction: row;
        gap: 8px;
        justify-content: right;
      }

      > * {
        width: 100%;
        margin-bottom: 8px;
        
        @media (min-width: $breakpoint-desktop) {
          max-width: 392px;
        }
      }
    }
  }
}

.sectionTitle {
  text-align: center;
  padding: 16px;
  
  // Responsive
  display: none;
  @media (min-width: $breakpoint-desktop) {
    display: block;
  }
}

body:has(.Recipe) {
  // Fix background for quick scrolling on mobile
  background-color: #E9E9E9;

  &::-webkit-scrollbar-track {
    background: #aaaaaa;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-color: #aaaaaa;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ececec;
  }
}
