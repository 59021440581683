@import "variables.scss";

.ChipFilter {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;

  .chip {
    background-color: $elevation-minus1;
    border-radius: 2px;

    padding: 8px;

    &.active {
      background-color: $elevation-minus2;
    }
  }
}